import React from 'react'
import {createInertiaApp} from '@inertiajs/react'
//import {hydrateRoot} from 'react-dom/client'
import { createRoot } from 'react-dom/client'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
/*import route from "ziggy-js";
import {Ziggy} from "./ziggy";*/
//globalThis.route = (name, params, absolute, config = Ziggy) => route(name, params, absolute, config);
globalThis.ssr = false;
globalThis.localMemory = {};


createInertiaApp({
  id: 'app',
  progress: {
    delay: 250,
    color: '#6C85F6',
    includeCSS: true,
    showSpinner: false,
  },
  resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
  setup({el, App, props}) {
    createRoot(el).render(<App {...props} />)
  },
}).then()
